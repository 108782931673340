export default {
  ru: {
    step1_create_popup_agreements: `Подтверждаю, что в отношениях с ООО «Хурма кредит МКК» действую сознательно,
    свободно, своей волей и в своих интересах, мои действия не контролируются
    иными третьими лицами, самостоятельно представляю свои интересы.!`,
    step1_create_link_log:
      "Если у Вас уже есть учётная <br/> запись на Hurmacredit, <a class='font-weight-bold' href='{href}'>войдите</a>.",
    view404_text:
      "Если у вас остались вопросы напишите нам <a href='mailto:support{'@'}hurmacredit.ru'>support{'@'}hurmacredit.ru</a> <br/> или обратитесь в службу поддержки по телефону <a class='page-404__phone' href='tel:+78005507268 '>8 800 550-72-68</a>",
  },
};
